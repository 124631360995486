import { makeStyles } from '@mui/styles';

const style = makeStyles((theme?: any) => ({
  section: {
    backgroundImage: `url(${process.env.NEXT_PUBLIC_CDN}/images/icons/dot.png)`,
  },
  textBox: {
    textAlign: 'center',
    width: '100%',
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
  },

  highlightText: {
    color: '#5f2afa',
    minHeight: 50,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    lineHeight: 0.8,
    display: 'inline-block',
  },

  [theme.breakpoints.up('md')]: {
    textBox: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
    highlightText: {
      minHeight: 87,
    },
  },
}));
export default style;
