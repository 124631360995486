import React from 'react';
import { Box, Typography } from '@mui/material';
import LazyImage from '../../Layouts/LazyImage';

const BackedBy = () => {
  return (
    <Box mt={5}>
      <Typography variant="caption" align="center" display="block">
        BACKED BY
      </Typography>

      <Box maxWidth="175px" margin="auto">
        <LazyImage sizes={sizes} src="/images/icons/ycombinator_logo.png" />
      </Box>
    </Box>
  );
};
const sizes = {
  xxl: [175, 51],
};

export default BackedBy;
