import React from 'react';
import { Box, Container } from '@mui/material';
import AchievementPointers from './AchievementPointers';

const ApplicationForm = ({ data }) => {
  return (
    <Container component={Box}>
      <AchievementPointers data={data?.pointers} />
    </Container>
  );
};

export default ApplicationForm;
