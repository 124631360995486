import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  breadcrumb: {
    '& ol': {
      paddingLeft: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
        paddingLeft: 0,
      },
    },
  },
}));
export default style;
