import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

import { makeStyles } from '@mui/styles';

const AchievementPointers = ({ data }) => {
  const classes = useStyle();

  return (
    <Grid container spacing={4}>
      {data?.map((point, key) => (
        <Grid item xs={12} sm={6} md={4} key={key}>
          <Box sx={{ minHeight: 100 }}>
            <Typography variant="h4" className={classes.title}>
              {point?.title}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {point?.sub}
            </Typography>
            <Typography variant="body1">{point?.para}</Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default AchievementPointers;

const useStyle = makeStyles((theme: any) => ({
  title: {
    color: theme.palette.tertiary.purplishBlue,
  },
}));
