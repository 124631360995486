import React from 'react';
import Head from 'next/head';
import fs from 'fs';
import path from 'path';

import Layout from '../components/Layout';
import Home from '../components/Home';

const Index = ({ content }) => (
  <Layout page="Home">
    <Head>
      {content?.meta?.title && <title>{content.meta.title}</title>}
      {content?.meta?.description && (
        <meta
          data-hid="twitterDescription"
          name="twitter:description"
          content={content.meta.description}
        />
      )}
      {/* <meta
        name="facebook-domain-verification"
        content="hs588js9o2jbpg5bffomaek9cfuq8l"
      /> */}

      {content?.meta?.description && (
        <meta name="description" content={content.meta.description} />
      )}
      {content?.meta?.description && (
        <meta name="keywords" content={content.meta.keywords} />
      )}
      <meta
        property="og:image"
        content={`${process.env.NEXT_PUBLIC_CDN}${
          content?.meta?.image
            ? content?.meta?.image
            : '/images/metaImage/default.jpg'
        }`}
      ></meta>
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="oneistox.com" />
      <meta property="twitter:url" content="https://www.oneistox.com/" />
      <meta
        name="twitter:title"
        content="Online Professional Courses for Designers, Architects & Engineers"
      />
      <meta
        name="twitter:description"
        content="Browse online cohort based courses for designers, architects and engineers to advance their professional growth. Join the tribe of 2000+ learners from 35+ countries today!"
      ></meta>
      <meta
        name="twitter:image"
        content={`${process.env.NEXT_PUBLIC_CDN}${
          content?.meta?.image
            ? content?.meta?.image
            : '/images/metaImage/default.jpg'
        }`}
      />
      <meta property="og:url" content="https://www.oneistox.com/" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Online Professional Courses for Designers, Architects & Engineers"
      />
      <meta
        property="og:description"
        content="Browse online cohort based courses for designers, architects and engineers to advance their professional growth. Join the tribe of 2000+ learners from 35+ countries today!"
      ></meta>
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="627" />
      <meta property="og:image:alt" content={content?.meta?.title} />
      <meta
        name="google-site-verification"
        content="0_vEVXsG0EHHwsrl0eAju3YCcJ8WQ-iCaOe0RUzl5Cc"
      />

      {/* <!-- Start Schema Tag Code --> */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: organizationalSchemaScript }}
      ></script>
      {/* <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: videoSchemaScript }}
      ></script> */}
      {/* <!-- End Schema Tag Code --> */}

      {/*<meta*/}
      {/*  property="og:video"*/}
      {/*  content={`${process.env.NEXT_PUBLIC_ASSETS}/videos/home.m4v`}*/}
      {/*/>*/}
      {/*<meta property="og:video:width" content="1200" />*/}
      {/*<meta property="og:video:height" content="600" />*/}
    </Head>
    <Home data={content} />
  </Layout>
);

export const getStaticProps = async () => {
  let homePageData: any = fs
    .readFileSync(path.join('content/home.json'))
    .toString();
  let bimPageData: any = fs
    .readFileSync(
      path.join('content/waitlist-page/building-information-modelling.json'),
    )
    .toString();
  let rhinoPageData: any = fs
    .readFileSync(
      path.join(
        'content/certifiedCourses/parametric-modelling-online-certification-course.json',
      ),
    )
    .toString();

  homePageData = JSON.parse(homePageData);
  bimPageData = JSON.parse(bimPageData);
  rhinoPageData = JSON.parse(rhinoPageData);

  return {
    props: {
      content: {
        ...homePageData,
        testimonial: {
          ...bimPageData.testimonial,
        },
      },
    },
  };
};

const organizationalSchemaScript = `{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Oneistox",
  "url": "https://www.oneistox.com/",
  "sameAs": [
    "https://www.facebook.com/oneistox/",
    "https://www.instagram.com/oneistox/",
    "https://www.youtube.com/channel/UC43uuiLYbswvwqgaSdepBpg",
    "https://www.linkedin.com/company/one-x-india"
  ] 
}`;

const videoSchemaScript = `{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Matthew",
  "description": "Feedback 1",
 "thumbnailUrl": "${process.env.NEXT_PUBLIC_ASSETS}/videos/testimonials/Testimonials_Mathew.mp4"
 },
 {
 "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Ayya",
  "description": "Feedback 2",
  "thumbnailUrl": "${process.env.NEXT_PUBLIC_ASSETS}/videos/testimonials/Testimonials_Aya%20Second%20Take_Compressed.mp4"
  },
  {
 "@context": "https://schema.org", 
"@type": "VideoObject",
  "name": "Una",
  "description": "Feedback 3",
  "thumbnailUrl": "${process.env.NEXT_PUBLIC_ASSETS}/videos/testimonials/Testimonials_Una_Compressed.mp4"
  },
  {
"@context": "https://schema.org", 
"@type": "VideoObject",
  "name": "Marvit",
  "description": "Feedback 4",
  "thumbnailUrl": "${process.env.NEXT_PUBLIC_ASSETS}/videos/testimonials/Testimonials_Marvit.mp4"
  },
  {
"@context": "https://schema.org",   
"@type": "VideoObject",
  "name": "Cristina",
  "description": "Feedback 5",
  "thumbnailUrl": "${process.env.NEXT_PUBLIC_ASSETS}/videos/testimonials/Testimonials_Cristina.mp4"
}`;

export default Index;
