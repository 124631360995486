import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

import PrimaryButton from '../../Layouts/MaterialUi/PrimaryButton';
import LazyImage from '../../Layouts/LazyImage';

import style from './style';
import ActiveLink from '../../Layouts/ActiveLink';

interface Props {
  data: any;
  riversedTile?: boolean;
  isBlog?: boolean;
  isBtnFalse?: boolean;
}

const CTASection = ({ data, riversedTile, isBtnFalse }: Props) => {
  const classes = style();

  return (
    <Container
      component={Box}
      my={{ xs: 7, md: 10 }}
      className={`${riversedTile ? classes.riversedTile : ''} ${
        classes.basicTile
      }`}
    >
      <Grid
        container
        sx={{ minHeight: { xs: 600, sm: 200, md: 300 } }}
        justifyContent="space-between"
      >
        <Grid item xs={12} sm={4}>
          <LazyImage
            sx={{ minHeight: 300 }}
            sizes={sizes}
            src={data?.img}
            className={classes.imgBox}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <Box className={classes.contentBox}>
            <Typography variant="h3" paragraph>
              {data?.title}
            </Typography>
            <Box mt={4} />
            {!isBtnFalse && (
              <ActiveLink href="/#certification-course">
                <>
                  <PrimaryButton
                    disableElevation
                    size="large"
                    className={classes.btn}
                  >
                    View Courses
                  </PrimaryButton>{' '}
                </>
              </ActiveLink>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const sizes = {
  xxl: [490, 490],
};

export default CTASection;
