import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  tileBox: {
    position: 'relative',
    backgroundColor: '#F7F4FF',
    overflow: 'hidden',
    minHeight: 390,
    height: '100%',
    '&.MuiTypography-h5': {
      color: '#e1e1e1',
    },
  },

  tileBoxActive: {
    transition: '0.3s',
    '&:hover': {
      boxShadow: '0 4px 20px #8d8d8d',
      '& .MuiButton-root': {
        background: theme.palette.primary.main,
        color: '#fff',
        border: '1px solid transparent',
      },
    },
  },

  professional: {
    backgroundColor: '#602afa',
    color: '#fff',
  },
  student: {
    backgroundColor: '#faeb50',
    '& .MuiChip-root': {
      color: '#faeb50',
    },
  },
  chip: {
    '&.MuiChip-root': {
      borderRadius: 4,
      fontSize: 16,
      overflow: 'auto',
      border: '1px solid black',
    },
  },
  bim: {
    '&.MuiChip-root': {
      backgroundColor: '#1efaaa',
      color: '#602afa',
    },
  },
  rhino: {
    '&.MuiChip-root': {
      color: '#ffffff',
    },
  },
  courseImg: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '170px',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.67)',
    zIndex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    '& > div': {
      '& svg': {
        fontSize: theme.spacing(7),
        opacity: 0.5,
        marginRight: '3px',
      },
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(3),
      display: 'flex',
      alignItems: 'flex-end',
    },
  },
  [theme.breakpoints.up('md')]: {
    tileBox: {
      minHeight: 264,
    },

    courseImg: {
      position: 'absolute',
      bottom: 0,
      right: -9,
      width: '200px',
    },
  },
}));
export default style;
