import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

import style from './style';
import PrimaryButton from '../../Layouts/MaterialUi/PrimaryButton';

const Hero = ({ data }) => {
  const classes = style();

  return (
    <Container component="section" maxWidth={false} className={classes.section}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={12} xl={10}>
          <Box className={classes.textBox}>
            <Typography variant="h1" sx={{ minHeight: 200 }} gutterBottom>
              {/* {data?.title} */}
              <span className={classes.highlightText}>Skyrocketing</span>{' '}
              architecture, construction, and design{' '}
              <span className={classes.highlightText}>careers</span>
            </Typography>

            <Box
              maxWidth={700}
              marginX={{ md: 'auto' }}
              sx={{ minHeight: { xs: 100, md: 50 } }}
              mb={{ md: 6 }}
            >
              <Typography variant="h6" paragraph>
                {data && data.description}
              </Typography>
            </Box>

            <PrimaryButton
              disableElevation
              size="large"
              href="#certification-course"
            >
              VIEW COURSES
            </PrimaryButton>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Hero;
