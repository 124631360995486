import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';

import React from 'react';
import ActiveLink from '../../Layouts/ActiveLink';

import LazyImage from '../../Layouts/LazyImage';

import style from './style';

const OtherOfferrings = ({ data }) => {
  const classes = style();

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <Container component={Box} py={{ xs: 5 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <Typography color="textPrimary" align="center" variant="h2">
            {data?.title}
          </Typography>
          <Typography
            color="textPrimary"
            align="center"
            variant="body1"
            paragraph
          >
            {data?.sub}
          </Typography>
        </Grid>
      </Grid>

      <Box mt={6} />

      <Grid container justifyContent="center">
        <Grid
          container
          item
          xs={12}
          md={10}
          justifyContent="center"
          spacing={8}
        >
          {data?.items.map((item, key) => (
            <Grid
              item
              xs={12}
              sm={5}
              md={4}
              key={key}
              className={classes.offerCard}
            >
              <ActiveLink href={item.url} target={item.newTab && '_blank'}>
                <div className={classes.itemBox}>
                  <Paper className={classes.paperBox}>
                    <LazyImage
                      sizes={sizes}
                      sx={{ minHeight: 95 }}
                      quality={95}
                      src={`${isSmallScreen ? item.phoneImg : item?.webImg}`}
                      radius={2}
                    />
                  </Paper>

                  <div>
                    <Typography
                      variant="h4"
                      sx={{ textAlign: { sm: 'center' } }}
                      gutterBottom
                    >
                      {item?.title}{' '}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ textAlign: { sm: 'center' } }}
                    >
                      {item?.para}{' '}
                    </Typography>
                  </div>
                </div>
              </ActiveLink>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

const sizes = {
  xxl: [278, 396],
};

export default OtherOfferrings;
