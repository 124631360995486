import React from 'react';
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { NavigateNext } from '@mui/icons-material';

import CourseTile from '../Layouts/CourseTile';
import style from './style';
import KeenSlider from '../Layouts/KeenSlider';

interface Props {
  data?: any;
  isStudent?: boolean;
}

const ProfessionalsCourse = ({ data, isStudent }: Props) => {
  const classes = style();
  const settings = {
    slides: {
      spacing: 10,
      perView: () => {
        return window.innerWidth / 325;
      },
    },
  };

  const matches = useMediaQuery('(min-width:600px)');

  return (
    <Container
      component={Box}
      my={{ xs: 7, md: 13 }}
      id={'certification-course'}
    >
      <Box textAlign="center" mb={5}>
        <Typography variant="h2" gutterBottom>
          {data?.title}
        </Typography>

        <Grid container justifyContent="center">
          <Grid item md={5}>
            {data?.para &&
              data?.para.map((para, key) => (
                <Typography
                  variant="body1"
                  color="textPrimary"
                  key={key}
                  paragraph
                >
                  {para}
                </Typography>
              ))}
          </Grid>
        </Grid>

        {!isStudent && (
          <Breadcrumbs
            className={classes.breadcrumb}
            separator={
              <NavigateNext
                fontSize="small"
                sx={{ display: { xs: 'none', sm: 'block' } }}
              />
            }
            aria-label="breadcrumb"
          >
            {data?.breadcrumbs?.map((data, key) => (
              <Typography color="textPrimary" key={key}>
                {key + 1}. {data}{' '}
              </Typography>
            ))}
          </Breadcrumbs>
        )}
      </Box>
      <Box>
        {matches ? (
          <Grid container justifyContent="center">
            <Grid item md={10}>
              <Box
                sx={{
                  display: 'grid',
                  gridGap: '16px',
                  gridTemplateColumns: '1fr 1fr',
                }}
              >
                {data?.tiles.map((tileData, key) => (
                  <CourseTile key={key} data={tileData} isStudent={isStudent} />
                ))}
              </Box>
            </Grid>
          </Grid>
        ) : (
          <KeenSlider settings={settings}>
            {data?.tiles.map((tileData, key) => (
              <div key={key} className="keen-slider__slide">
                <CourseTile data={tileData} isStudent={isStudent} />
              </div>
            ))}
          </KeenSlider>
        )}{' '}
      </Box>
    </Container>
  );
};

export default ProfessionalsCourse;
