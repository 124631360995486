import React from 'react';
import dynamic from 'next/dynamic';

import Hero from './Hero';
import OtherOfferrings from './OtherOfferrings';
const USPSection = dynamic(import('./USPSection'));
import CTASection from './CTASection';

const VideoTestimonial = dynamic(import('./VideoTestimonial'));
import ProfessionalsCourse from '../ProfessionalsCourse';

import Collaborations from './Collaborations';

import ApplicationForm from './ApplicationForm';
import BackedBy from './BackedBy/BackedBy';
import { useAppDispatch } from '../../hooks';
import { openChangePopup } from '../../actions/popup';

const Home = ({ data }) => {
  const reviewData = {
    reviews: [...data.testimonial.reviews],
  };

  // const dispatch = useAppDispatch();

  // setTimeout(() => {
  //   dispatch(
  //     openChangePopup(
  //       'VideoPopupWithCTA',
  //       'https://www.youtube.com/embed/9XOCYvdv3Ps',
  //     ),
  //   );
  // }, 1000);

  return (
    <>
      {data?.HeroData && <Hero data={data.HeroData} />}
      {data?.CollaborationsData && (
        <Collaborations data={data.CollaborationsData} />
      )}

      <BackedBy />

      {data?.professionCourses && (
        <ProfessionalsCourse data={data.professionCourses} />
      )}
      {data?.usp && <USPSection data={data?.usp} />}

      {data?.otherOffer && <OtherOfferrings data={data?.otherOffer} />}

      <VideoTestimonial data={data?.testimonial} reviewData={reviewData} />

      <ApplicationForm data={data?.applicationForm} />

      <CTASection data={data?.ctaSection2} riversedTile />
    </>
  );
};

export default Home;
