import { makeStyles } from '@mui/styles';

const style = makeStyles((theme?: any) => ({
  paperBox: {
    minHeight: 130,
    marginBottom: theme.spacing(1),
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    '& > div': {
      width: '100%',
    },
  },
  [theme.breakpoints.down('sm')]: {
    itemBox: {
      minHeight: 100,
      display: 'grid',
      gridTemplateColumns: '150px 1fr',
      gridGap: 12,
    },
  },
  offerCard: {
    cursor: 'pointer',
    transition: '0.3s',
    '& a': {
      display: 'block',
    },
    '&:hover': {
      '& .MuiTypography-h4': {
        color: theme.palette.primary.main,
      },
    },
  },
}));
export default style;
