import React from 'react';
import { Box, Chip, Typography } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { useRouter } from 'next/router';
import Mustache from 'mustache';

import PrimaryButton from '../MaterialUi/PrimaryButton';
import LazyImage from '../../Layouts/LazyImage';
import paymentPlansData from '../../../content/cart/payment-options.json';

import style from './style';
import dayjs from 'dayjs';

interface Props {
  data?: any;
  isStudent?: boolean;
}

const CourseTile = ({ data }: Props) => {
  const classes = style();
  const router = useRouter();

  const courseInfo = paymentPlansData.find(
    (param) => param.courseId === data.courseId,
  );

  let closingDays = dayjs(courseInfo?.openTill).diff(dayjs(), 'day');
  if (dayjs() >= dayjs(data?.closingDate)) {
    // Sets coming Monday date
    closingDays = dayjs()
      .add((7 - dayjs().day()) % 7, 'day')
      .startOf('date')
      .diff(dayjs(), 'day');
  }
  closingDays = closingDays > 0 ? closingDays : 0;
  return (
    <Box
      p={2}
      boxShadow={1}
      borderRadius={2}
      className={`${classes.tileBox} ${
        !data?.releasing ? classes.tileBoxActive : ''
      }`}
    >
      {data?.releasing && (
        <div className={`${classes.overlay}`}>
          <div>
            <LockOutlined fontSize="large" />{' '}
            <Typography variant="h5">{data?.releasing}</Typography>
          </div>
        </div>
      )}

      <Box sx={{ width: { md: '75%' } }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ minHeight: { xs: 92, md: '120px' } }}
        >
          {data?.title}
        </Typography>

        {!data?.releasing && (
          <Chip
            variant="outlined"
            className={`${classes.chip}`}
            label={Mustache.render(data.closing, {
              closingDays: closingDays + 1,
            })}
          />
        )}
        {!data?.releasing && (
          <Box mt={{ xs: 2, md: 6 }}>
            <PrimaryButton
              size="large"
              onClick={() => router.push(`certified-courses/${data?.url}`)}
            >
              Apply Now
            </PrimaryButton>
          </Box>
        )}
      </Box>
      <div className={classes.courseImg}>
        <LazyImage
          sizes={data.isBim ? bimSizes : sizes}
          quality={85}
          lazyLoadFalse
          src={data?.img}
        />
      </div>
    </Box>
  );
};

const sizes = {
  xxl: ['268', '369', false],
};
const bimSizes = {
  xxl: ['318', '369', false],
};
export default CourseTile;
