import { makeStyles } from '@mui/styles';

const style = makeStyles((theme?: any) => ({
  collaborationMain: { width: '100%', padding: '45px 0' },
  collaborationWrap: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 111px)',
    justifyContent: 'space-evenly',
    gridColumnGap: '10px',
    alignItems: 'center',
    marginTop: '20px',
    gridRowGap: '20px',
    '&$collabotation': {
      backgroundColor: 'black',
    },
  },
  heading: { marginBottom: '40px' },
  collabotation: {
    outline: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    // padding: '11px',
  },
  [theme.breakpoints.up('sm')]: {
    collaborationMain: {
      padding: '65px 0',

      '&:nth-child(n + 9)': { display: 'flex' },
    },
    collaborationWrap: {
      gridTemplateColumns: 'repeat(4, 135px)',
      gridRowGap: '55px',
      marginTop: '35px',
    },
  },
  [theme.breakpoints.up('lg')]: {
    collaborationWrap: {
      justifyContent: 'space-between',
      gridColumnGap: '68px',
      gridTemplateColumns: 'repeat(6, 1fr)',
      alignItems: 'flex-end',
    },
  },
}));

export default style;
