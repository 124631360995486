import { makeStyles } from '@mui/styles';

const style = makeStyles((theme?: any) => ({
  contentBox: {
    textAlign: 'center',
    padding: `${theme.spacing(5)} ${theme.spacing(3)}`,
  },

  imgBox: {
    height: '100%',
    '& img': {
      height: '100%',
    },
  },
  basicTile: {
    '& > div': {
      backgroundColor: '#5f2afa',
      color: '#fff',
      borderRadius: 8,
      overflow: 'hidden',
    },
  },

  riversedTile: {
    '& > div': {
      flexDirection: 'row-reverse',
    },
  },

  btn: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },

  [theme.breakpoints.up('sm')]: {
    contentBox: {
      paddingTop: 32,
      paddingBottom: 56,
      paddingRight: 32,
      textAlign: 'left',
    },
  },
}));
export default style;
