import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

import LazyImage from '../../Layouts/LazyImage';

import style from './style';

const Collaborations = ({ data }) => {
  const classes = style();
  return (
    <Container>
      <Grid container justifyContent="center">
        <Grid item md={12} className={classes.collaborationMain}>
          <div className={classes.heading}>
            <Typography
              gutterBottom
              variant="subtitle1"
              align="center"
              color="textSecondary"
            >
              {data && data.title}
            </Typography>
            <Typography variant="body1">{data && data.sub}</Typography>
          </div>

          {/* <div className={classes.collaborationWrap}>
            {data &&
              data.collab.map((val, key) => {
                return (
                  <Box className={classes.collabotation} key={key}>
                    <LazyImage
                      src={`/images/${val.img}`}
                      alt={`${val.name}`}
                    />
                  </Box>
                )
              })}
          </div> */}
          <Grid container justifyContent="center">
            <Grid
              justifyContent="center"
              alignItems="center"
              spacing={7}
              item
              md={9}
              container
            >
              {data &&
                data.collab.map((val, key) => (
                  <Grid item xs={5} sm={4} md={3} key={key}>
                    {' '}
                    <Box className={classes.collabotation} key={key}>
                      <LazyImage
                        sx={{ minHeight: 45 }}
                        src={`/images/${val.img}`}
                        alt={`${val.name}`}
                      />
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Collaborations;
